import Hero from '../../components/Hero/Hero';
import Features from '../../components/Features/Features';
import SignupForm from '../../components/SignupForm/SignupForm';
import Footer from '../../components/Footer/Footer';
import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles.home}>
      <Hero />
      <div id='readMore' className={styles.content}>
        <div  className={styles.introSection}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.introTitle}>
              The Uber for Storage
            </h2>
            <h3 className={styles.introSubtitle}>
              Made for Students, by Students.
            </h3>
          </div>
          <p className={styles.introText}>
            At Boxmate, we provide a unique, hassle-free storage experience by utilizing student apartments. This ensures your belongings are stored securely and conveniently in locations familiar to the student community.
          </p>
          <SignupForm />
        </div>
        <Features />
      </div>
      <Footer />
    </div>
  );
};
export default Home;