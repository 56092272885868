import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p>© 2024 Boxmate. All rights reserved.</p>
        <div className={styles.links}>
          <a href="mailto:support@boxmate.store" className={styles.contactLink}>
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
