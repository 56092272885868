import styles from "./Hero.module.css";
import BoxMateLogo from "../../assets/file.png";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Your Affordable Student
          <br />
          Summer Storage Solution
        </h1>
        <div className={styles.logoContainer}>
          <img src={BoxMateLogo} alt="Boxmate Logo" className={styles.logo} />
        </div>
        <p className={styles.subtitle}>Coming January 2025</p>
        <button
          className={styles.readMore}
          onClick={() => {
            document
              .getElementById("readMore")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Read More
        </button>
      </div>
    </div>
  );
};

export default Hero;
