import React from 'react';
import styles from './SignupForm.module.css';

const SignupForm = () => {
  return (
    <section className={styles.signup}>
      <form action="https://store.us11.list-manage.com/subscribe/post?u=5abc0665bc7acdca99a2f4b2c&amp;id=72b61a25ec&amp;f_id=00aba8e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <div className={styles.formContainer}>
          <input 
            type="email" 
            name="EMAIL" 
            className={styles.emailInput}
            id="mce-EMAIL" 
            placeholder="Enter your email for early access" 
            required
          />
          <button type="submit" name="subscribe" id="mc-embedded-subscribe" className={styles.submitButton}>
            Sign Up
          </button>
        </div>
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
          <input type="text" name="b_5abc0665bc7acdca99a2f4b2c_72b61a25ec" tabIndex="-1" value="" readOnly />
        </div>
      </form>
    </section>
  );
};

export default SignupForm;
