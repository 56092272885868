import styles from './Features.module.css';

const Features = () => {
  const features = [
    {
      title: 'Store with ease',
      description: 'Safe storage in student apartments.'
    },
    {
      title: 'Flexible scheduling',
      description: 'Choose pickup & drop-off dates that suit you.'
    },
    {
      title: 'Unbeatable pricing',
      description: 'Starting at $6/month for student-friendly storage.'
    }
  ];

  return (
    <section id="features" className={styles.features}>
      <div className={styles.grid}>
        {features.map((feature, index) => (
          <div key={index} className={styles.card}>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
